import React from "react";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import ContactForm from "../../components/ContactForm/ContactForm";
import OpportunidadeHero from "../../assets/images/oportunidadehero.jpg";
import RecruitmentSection from "./RecruitmentSection";

const RecruitmentPage: React.FC = () => {
  return (
    <>
      <Nav />
      <HeroSection
        title="Opportunidade Ritello"
        subtitle="Junte-se á nossa equipa"
        backgroundImage={OpportunidadeHero}
      />

      <RecruitmentSection />
      <ContactForm />

      <Footer />
    </>
  );
};

export default RecruitmentPage;
