import React from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import HomePage from "../../pages/Home/HomePage";
import ContactPage from "../../pages/Contact/ContactPage";
import FAQPage from "../../pages/FAQ/FAQPage";
import RecruitmentPage from "../../pages/Recruitment/RecruitmentPage";
import SocialResponsibilityPage from "../../pages/SocialResponsibility/SocialResponsibility";
import CleaningSuggestionsPage from "../../pages/CleaningSuggestions/CleaningSuggestions";
import AssistancePage from "../../pages/Assistance/AssistancePage";
import ProductPage from "../../pages/Product/ProductPage";
import HistoryPage from "../../pages/History/HistoryPage";
import ProductDetail from "../ProductComponents/ProductDetail/ProductDetail";
import CampaignPage from "../../pages/Campaign/CampaignPage";
import CertificatePage from "../../pages/Certificate/CertificatePage";
import PrivacyPage from "../../pages/Privacy/PrivacyPage";
import TermsOfServicePage from "../../pages/TermsOfService/TermsOfService";
import PurelloFormPage from "../../pages/PurelloForm/PurelloFormPage";
import VacationFormPage from "../../pages/VacationForm/VacationFormPage";
import IronFormPage from "../../pages/IronForm/IronFormPage";

const LanguageRoutes: React.FC = () => {
  const { lng } = useParams<{ lng: string }>();
  const language = lng || "pt";

  const { t, i18n } = useTranslation();

  // Change language if necessary
  React.useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const removeLeadingSlash = (path: string) => path.replace(/^\//, "");

  return (
    <>
      {/* Include ScrollToTop if needed */}
      <ScrollToTop />
      <Routes>
        <Route index element={<HomePage />} />

        <Route
          path={removeLeadingSlash(t("routes.contact"))}
          element={<ContactPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.purello_form_page"))}
          element={<PurelloFormPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.vacation_form_page"))}
          element={<VacationFormPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.iron_form_page"))}
          element={<IronFormPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.faq"))}
          element={<FAQPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.become_agent"))}
          element={<RecruitmentPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.social_responsibility"))}
          element={<SocialResponsibilityPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.cleaning_suggestions"))}
          element={<CleaningSuggestionsPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.assistance"))}
          element={<AssistancePage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.products"))}
          element={<ProductPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.our_history"))}
          element={<HistoryPage />}
        />
        <Route
          path={`${removeLeadingSlash(t("routes.product"))}/:productId`}
          element={<ProductDetail />}
        />
        <Route
          path={removeLeadingSlash(t("routes.campaigns"))}
          element={<CampaignPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.certificates"))}
          element={<CertificatePage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.privacy"))}
          element={<PrivacyPage />}
        />
        <Route
          path={removeLeadingSlash(t("routes.terms"))}
          element={<TermsOfServicePage />}
        />

        {/* Catch-all route to handle undefined routes */}
        <Route
          path="*"
          element={<Navigate to={`/${language}${t("routes.home")}`} replace />}
        />
      </Routes>
    </>
  );
};

export default LanguageRoutes;
